<template>
  <div class="not-found">
    <header class="not-found__title mb-y-gutter-sm">
      <h1 class="heading font-antidote">
        page not found
      </h1>
    </header>
    <div class="not-found__subtitle mb-y-gutter-sm">
      <strong>not sure where it is, but it's definitely not here</strong>
    </div>
    <div class="not-found__cta">
      <cta class="text-xl whitespace-no-wrap" fill @click.stop.prevent="click">
        RECESS THIS WAY
      </cta>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      default: () => {},
      type: Object
    }
  },
  methods: {
    click() {
      this.$router.push('/shop/collections/shopall')
    }
  }
}
</script>

<style>
.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--win-height);
}

.not-found__title {
  text-align: center;
}

.not-found__subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: var(--container-gutter);
  margin-right: var(--container-gutter);
  text-align: center;
}

.not-found__cta {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .not-found__subtitle {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .not-found__subtitle {
    font-size: 1.125rem;
  }
}
</style>
